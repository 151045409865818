import { Footer } from './Components/Footer'
import { NavBar } from './Components/NavBar'
import { About } from './Pages/About'
import { Contact } from './Pages/Contact'
import { Home } from './Pages/Home'
import { OurNumbers } from './Pages/OurNumbers'
import { Partnership } from './Pages/Partnership'
import { Proccess } from './Pages/Proccess'
import { Whywe } from './Pages/Whywe'
import { Work } from './Pages/Work'
import { Element } from 'react-scroll'


export const App = () => {
  return (
    <>
      <NavBar />
      <Element name='/'><Home /> </Element>
      <Element name='/about'><About /> </Element>
      <Element name='/whywe'><Whywe /> </Element>
      <Element name='/skills'><Work /> </Element>
      <Element name='/procces'><Proccess /> </Element>
      <Element name='/numbers'><OurNumbers /> </Element>
      <Element name='/partnership'><Partnership /> </Element>
      <Element name='/contact'><Contact /> </Element>
      <Footer />
    </>

  )
}
