import React, { useRef, useEffect, useState } from 'react';
import './Proccess.css'

export const Whywe = () => {
    const aboutRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const { top, bottom } = aboutRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;

            // Check if the top or bottom of the about section is in the viewport
            if (top < windowHeight && bottom > 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div id='proccess' ref={aboutRef} className={`flex bg-slate-200  flex-col h-full w-screen justify-center items-center py-16 ${isVisible ? 'animated' : ''}`}>
            <h1 className={`text-3xl sm:text-5xl font-semibold text-center mb-6 uppercase ${isVisible ? 'animated-title' : ''}`}>Pourquoi choisir  <span className='text-[#db6f15]'>Solabeat</span></h1>
            <div className='flex flex-col md:flex-row gap-y-5 justify-center px-14 py-10 '>
                <div className='flex flex-col justify-between w-full'>
                    <div className={`${isVisible ? 'animated-left-to-right1' : ''}`}> <span className='mb-3 text-2xl block font-semibold text-[#db6f15]'>1. Expertise technique</span>
                        Solabeat possède une expertise technique approfondie dans les domaines de l'informatique et des télécommunications, lui permettant ainsi de proposer des solutions innovantes et performantes pour répondre aux besoins de ses clients.</div>
                    <div className={`${isVisible ? 'animated-left-to-right2' : ''}`}><span className=' mt-4 md:mt-0 mb-3 text-2xl block font-semibold text-[#db6f15]'>2. Qualité des services</span>
                        Solabeat met l'accent sur la qualité de ses services, garantissant des solutions fiables, sécurisées et évolutives pour ses clients.</div>

                </div>
                <div className='w-1/2'></div>
                {/* <iframe className='w-full' src="https://lottie.host/embed/5a774659-0443-48e3-b8f5-6a3a281bc348/RTrXnyETTb.json" width={"350px"} height={"300px"}></iframe> */}
                <div className='w-full flex flex-col gap-y-5 justify-between'>
                    <div className={`${isVisible ? 'animated-left-to-right3' : ''}`}> <span className='mb-3 text-2xl block font-semibold text-[#db6f15]'>3. Satisfaction client</span>
                        Chez Solabeat, la satisfaction client est primordiale. Nous offrons un service client de qualité supérieure, écoutons attentivement les besoins de nos clients et travaillons en étroite collaboration avec eux pour trouver des solutions adaptées.</div>
                    <div className={`${isVisible ? 'animated-left-to-right4' : ''}`}><span className='mb-3 text-2xl block font-semibold text-[#db6f15]'>4. Flexibilité</span>
                        Solabeat est flexible et capable de s'adapter aux besoins uniques de ses clients en fournissant des solutions personnalisées et flexibles pour répondre à toute situation.</div>

                </div>
            </div>
        </div>
    )
}
