import Slider from 'react-infinite-logo-slider'
import apple from '../Assets/partenaires/apple.png'
import aver from '../Assets/partenaires/aver.png'
import cisco from '../Assets/partenaires/cisco.png'
import dinstar from '../Assets/partenaires/dinstar.png'
import dlink from '../Assets/partenaires/dlink.png'
import fanvil from '../Assets/partenaires/fanvil.png'
import flyingvoice from '../Assets/partenaires/flyingvoice.png'
import grandstrem from '../Assets/partenaires/grandstrem.png'
import hikvision from '../Assets/partenaires/hikvision.png'
import logitech from '../Assets/partenaires/logitech.png'
import microsoft from '../Assets/partenaires/microsoft.png'
import openvox from '../Assets/partenaires/openvox.png'
import tplink from '../Assets/partenaires/tplink.png'
import yeastar from '../Assets/partenaires/yeastar.png'
import zkteco from '../Assets/partenaires/zkteco.png'

export const Partnership = () => {

    return (
        <div className='w-screen bg-slate-200 py-16'>
            <h1 className='text-3xl sm:text-5xl uppercase mb-10 font-semibold text-center'>notre <span className='text-[#db6f15]'>Partenaire</span></h1>
            <Slider
                width="250px"
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                // toRight={false}
                blurBoderColor={'#fff'}
            >
                <Slider.Slide>
                    <img src={apple} alt="apple" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={aver} alt="aver" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={cisco} alt="cisco" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={dinstar} alt="dinstar" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={dlink} alt="dlink" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={fanvil} alt="fanvil" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={yeastar} alt="yeastar" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={zkteco} alt="zkteco" className='w-36' />
                </Slider.Slide>
            </Slider>

            <Slider
                width="250px"
                duration={40}
                pauseOnHover={true}
                blurBorders={false}
                // toRight={true}
                blurBoderColor={'#fff'}
            >
                <Slider.Slide>
                    <img src={flyingvoice} alt="flyingvoice" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={grandstrem} alt="grandstrem" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={hikvision} alt="hikvision" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={logitech} alt="logitech" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={microsoft} alt="microsoft" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={openvox} alt="openvox" className='w-36' />
                </Slider.Slide>
                <Slider.Slide>
                    <img src={tplink} alt="tplink" className='w-36' />
                </Slider.Slide>

            </Slider>
        </div>
    )
}

