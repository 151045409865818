import React, { useRef, useEffect, useState } from 'react';
import './Proccess.css'

export const Proccess = () => {
    const aboutRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const { top, bottom } = aboutRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;

            // Check if the top or bottom of the about section is in the viewport
            if (top < windowHeight && bottom > 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);

        // Cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <div id='proccess' ref={aboutRef} className={`flex bg-slate-200 flex-col h-full w-screen justify-center items-center mt-5 py-16  ${isVisible ? 'animated' : ''}`}>
            <h1 className={`text-3xl sm:text-5xl font-semibold mb-6 uppercase ${isVisible ? 'animated-title' : ''}`}>notre <span className='text-[#db6f15]'> procedure</span></h1>
            <div className='flex flex-col md:flex-row gap-y-5 justify-center px-14 py-10 '>
                <div className='flex flex-col justify-between w-full'>
                    <div className={`${isVisible ? 'animated-left-to-right1' : ''}`}> <span className='mb-3 text-2xl block font-semibold text-[#db6f15]'>1. Analyse du besoin</span>
                        Recevoir le besoin de la part de client et analyse de la situation.</div>
                    <div className={`${isVisible ? 'animated-left-to-right2' : ''}`}><span className=' mt-4 md:mt-0 mb-3 text-2xl block font-semibold text-[#db6f15]'>2. Proposition</span>
                        L’envoi d’une proposition avec nos services et des solutions adéquates aux besoins.</div>

                </div>
                <iframe className='w-full' src="https://lottie.host/embed/5a774659-0443-48e3-b8f5-6a3a281bc348/RTrXnyETTb.json" width={"350px"} height={"300px"}></iframe>
                <div className='w-full flex flex-col gap-y-5 justify-between'>
                    <div className={`${isVisible ? 'animated-left-to-right3' : ''}`}> <span className='mb-3 text-2xl block font-semibold text-[#db6f15]'>3. Mise en Service</span>
                        Installation et mise en place des solutions proposées et validées.</div>
                    <div className={`${isVisible ? 'animated-left-to-right4' : ''}`}><span className='mb-3 text-2xl block font-semibold text-[#db6f15]'>4. Suivi et Maintenance</span>
                        Suivi et maintenance après finalisation du projet et proposition d’autres services.</div>

                </div>
            </div>
        </div>
    )
}
