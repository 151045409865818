import React, { useState } from 'react';
import visio from '../Assets/visioconference-moniteur-video.jpg';
import voip from '../Assets/solution-voip.jpg';
import crm from '../Assets/definition-crm.png';
import brand from '../Assets/Projects/brandApp1.png'
import gestion from '../Assets/Projects/gestion.png'
import chatapp from '../Assets/Projects/chatapp.png'
import weather from '../Assets/Projects/weather-app.jpeg'
import './work.css'

export const Work = () => {
    const allProjects = [

        {
            projectName: "Store Website",
            projectImage: brand,
            technologies: ["ReactJS", "CSS", "Tailwind CSS", "JavaScript", "PHP", "MySQL"],
            category: "dev",
            popupTitle: "Store Website",
            popupDescription: "Experience seamless shopping with our React-Redux powered store website. Browse effortlessly, shop confidently",
            fonctionTitle: "FONCTIONNALITÉS AVANCÉES",
            fonctionTitleList: [
                "User Authentication",
                "Product Catalog Management",
                "Shopping Cart",
                "Secure Checkout"
            ],
            fonctionDescriptionList: [
                "Allow users to create accounts and securely log in to access personalized features.",
                "Efficiently manage products with options for categorization, filtering, and sorting.",
                "Enable users to add items to their shopping carts and manage quantities before checkout.",
                "Implement a secure payment gateway to facilitate safe and reliable transactions."
            ],
            description: "Discover a seamless shopping experience with our React-Redux powered store website. Effortless browsing, secure transactions, and endless possibilities await!"
        },
        {
            popupTitle: "La Téléphonie IP / VoIP",
            popupDescription: "Imaginez un système téléphonique qui s’adapte à vos besoins, où que vous soyez. Avec la téléphonie IP, vos communications vocales et vidéo se transforment en une expérience fluide et collaborative, accessible depuis n’importe quel appareil connecté à internet.",
            fonctionTitle: "FONCTIONNALITÉS AVANCÉES DE LA TÉLÉPHONIE IP",
            fonctionTitleList: [
                "FONCTIONNALITÉS AVANCÉES DE LA TÉLÉPHONIE IP               ",
                "Messagerie instantanée et visioconférence",
                "Conférence téléphonique   ",
                "Intégration CRM",
                "Mobilité",
                "Analyse des appels",
            ],
            fonctionDescriptionList: [
                "Accueillez vos clients de manière professionnelle et orientez-les vers le bon service en fonction de leurs besoins.                ",
                "Communiquez instantanément avec vos collaborateurs et vos clients, où qu’ils soient.                ",
                "Organisez des réunions à distance avec plusieurs participants en toute simplicité.",
                "Accédez aux informations clients directement depuis votre interface téléphonique et offrez un service personnalisé.                ",
                "Accédez à votre ligne téléphonique et à vos fonctionnalités depuis n’importe quel appareil connecté à internet.                ",
                "Suivez et analysez vos appels pour identifier les points d’amélioration et optimiser vos performances."
            ],
            projectName: "Telephonie IP",
            projectImage: voip,
            category: 'voip',
            description: "Description of Telephonie IP project", // Add descriptions for each project
            technologies: ['3CX', 'YEASTAR', 'DINSTAR', 'GRANDSTREAM', 'OPENVOX '] // Add technologies for each project
        },
        {
            projectName: "Chat App",
            projectImage: chatapp,
            technologies: ["Flutter", "FireBase", "OOP", "Dart"],
            category: "dev",
            popupTitle: "Chat App",
            popupDescription: "A dynamic and feature-rich chat application built with Flutter and Firebase. Harnessing the power of Object-Oriented Programming (OOP) principles and the Dart programming language, this app delivers a seamless messaging experience.",
            fonctionTitle: "FONCTIONNALITÉS AVANCÉES",
            fonctionTitleList: ["Real-time Messaging", "Group Chats", "Media Sharing"],
            fonctionDescriptionList: ["Engage in real-time conversations with friends and family, with instant message delivery.", "Create and participate in group chats with multiple users, making communication efficient and fun.", "Share photos, videos, and documents effortlessly, enhancing your messaging experience."],
            description: "Explore our Chat App project, a dynamic messaging platform powered by Flutter, Firebase, OOP, and Dart. Connect with others in real-time, share moments, and stay connected like never before."
        },
        {
            projectName: "Stock Management App",
            projectImage: gestion,
            technologies: ["Flutter", "PHP", "OOP", "Dart"],
            category: "dev",
            popupTitle: "Stock Management App",
            popupDescription: "A comprehensive stock management application developed using Flutter for the frontend and PHP for the backend. Built with Object-Oriented Programming (OOP) principles and adhering to clean architecture, this app offers robust features for managing inventory efficiently.",
            fonctionTitle: "ADVANCED FEATURES",
            fonctionTitleList: ["Product Management", "Inventory Tracking", "Order Management"],
            fonctionDescriptionList: ["Efficiently manage products, including adding, editing, and deleting items from the inventory.", "Track inventory levels in real-time, receive alerts for low stock, and optimize inventory management.", "Manage orders, track order status, and streamline the order fulfillment process for improved efficiency."],
            description: "Discover our Stock Management App, a powerful tool for managing inventory seamlessly. With Flutter, PHP, OOP, and Dart at its core, this app offers advanced features for product management, inventory tracking, and order management, empowering businesses to streamline operations."
        },

        {
            popupTitle: "Visioconférence",
            popupDescription: "Experience seamless video conferencing with our Visioconférence project. Connect with colleagues and clients effortlessly, fostering collaboration and productivity.",
            fonctionTitle: "FONCTIONNALITÉS AVANCÉES",
            fonctionTitleList: [
                "Visioconférence",
                "Screen Sharing",
                "Chat Integration",
                "Recording Feature"
            ],
            fonctionDescriptionList: [
                "Share your screen with participants to facilitate presentations and discussions.",
                "Integrate chat functionality within the conferencing platform for real-time communication.",
                "Record meetings for future reference and sharing with absent participants."
            ],
            projectName: "Visioconférence",
            projectImage: visio,
            category: "voip",
            description: "Discover our Visioconférence project, designed to revolutionize remote communication. Utilizing cutting-edge technologies from Aver, Logitech, and Yealink, this platform offers an immersive conferencing experience.",
            technologies: ["Aver", "Logitech", "Yealink"]
        },
        {
            projectName: "Weather App",
            projectImage: weather,
            technologies: ["ReactJS", "CSS", "Tailwind CSS", "JavaScript", "WeatherAPI"],
            category: "dev",
            popupTitle: "Weather App",
            popupDescription: "A sleek and intuitive weather application developed using ReactJS and powered by the WeatherAPI. With a clean user interface crafted using CSS and Tailwind CSS, this app provides accurate weather forecasts at your fingertips.",
            fonctionTitle: "FONCTIONNALITÉS AVANCÉES",
            fonctionTitleList: ["Current Weather", "Hourly Forecast", "Daily Forecast"],
            fonctionDescriptionList: ["Get up-to-date information on the current weather conditions in your location.", "View detailed hourly forecasts to plan your day effectively.", "Stay informed with daily weather forecasts, helping you prepare for the week ahead."],
            description: "Discover our Weather App project, an elegant solution for accessing weather forecasts built with ReactJS, CSS, Tailwind CSS, JavaScript, and WeatherAPI. Stay informed about weather changes and plan your activities with ease."
        },
        {
            popupTitle: "CRM",
            popupDescription: "Streamline your business operations with our CRM project. Manage customer relationships efficiently and enhance productivity.",
            fonctionTitle: "FONCTIONNALITÉS AVANCÉES",
            fonctionTitleList: [
                "CRM",
                "Customer Management",
                "Sales Pipeline Tracking",
                "Analytics Dashboard"
            ],
            fonctionDescriptionList: [
                "Efficiently manage customer interactions, including contact details, communication history, and preferences.",
                "Track the progress of sales opportunities through customizable pipelines, ensuring no opportunity falls through the cracks.",
                "Gain valuable insights into business performance with an intuitive analytics dashboard, providing key metrics and trends."
            ],
            projectName: "CRM",
            projectImage: crm,
            category: "erp",
            description: "Discover our CRM project, designed to optimize customer relationship management. Utilizing powerful technologies like Odoo, Vtiger, Zoho, and Salesforce, this platform empowers businesses to deliver exceptional customer experiences.",
            technologies: ["Odoo", "Vtiger", "Zoho", "Salesforce"]
        }

    ];

    const [projects, setProjects] = useState(allProjects);
    const [activeCategory, setActiveCategory] = useState('all');
    const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
    const [selectedProject, setSelectedProject] = useState(null); // State to store selected project

    const filterProjects = (category) => {
        if (category === 'all') {
            setProjects(allProjects);
        } else {
            const filteredProjects = allProjects.filter(project => project.category.toLowerCase() === category.toLowerCase());
            setProjects(filteredProjects);
        }
        setActiveCategory(category.toLowerCase());
    };

    const handlePopupOpen = (project) => {
        setSelectedProject(project);
        setShowPopup(true);
    };

    const handlePopupClose = () => {
        setShowPopup(false);
        setSelectedProject(null);
    };

    return (
        <div id='work' className='h-fit w-screen flex flex-col items-center py-16 '>
            <h1 className='text-3xl sm:text-5xl uppercase font-semibold my-10 text-center'>NOS <span className='text-[#db6f15]'>SOLUTIONS</span></h1>
            <div className='flex gap-x-7 flex-wrap gap-4 justify-center '>
                <button onClick={() => filterProjects('all')} className={activeCategory === 'all' ? 'bg-[#db6f15]  rounded-lg  h-10 px-4 text-white font-semibold text-sm ' : ' font-semibold text-sm rounded-lg h-10 px-4 bt'}>All</button>
                <button onClick={() => filterProjects('VoIP')} className={activeCategory === 'voip' ? 'bg-[#db6f15] rounded-lg h-10 px-4 text-white font-semibold text-sm' : 'font-semibold text-sm rounded-lg h-10 px-4 bt'}>VoIP</button>
                <button onClick={() => filterProjects('erp')} className={activeCategory === 'erp' ? 'bg-[#db6f15] rounded-lg h-10 px-4 text-white font-semibold text-sm' : 'font-semibold text-sm rounded-lg h-10 px-4 bt'}>CRM & ERP</button>
                <button onClick={() => filterProjects('IT')} className={activeCategory === 'it' ? 'bg-[#db6f15] rounded-lg h-10 px-4 text-white font-semibold text-sm' : 'font-semibold text-sm rounded-lg h-10 px-4 bt'}>Infrastructure IT</button>
                <button onClick={() => filterProjects('Dev')} className={activeCategory === 'dev' ? 'bg-[#db6f15] rounded-lg h-10 px-4 text-white font-semibold text-sm' : 'font-semibold text-sm rounded-lg h-10 px-4 bt'}>Dev</button>
                <button onClick={() => filterProjects('all-in-one')} className={activeCategory === 'all-in-one' ? 'bg-[#db6f15] rounded-lg h-10 px-4 text-white font-semibold text-sm' : 'font-semibold text-sm rounded-lg h-10 px-4 bt'}>All-in-one</button>
            </div>

            {/* Popup element */}
            {selectedProject && (
                <div className='popup overflow-auto' >
                    {selectedProject && (
                        <div className='popup-content max-w-md bg-white rounded-lg shadow-md px-8 py-8'>
                            <div className='flex justify-between items-center border-b border-gray-200 pb-4'>
                                <h2 className='text-2xl font-semibold text-gray-800'>{selectedProject.projectName}</h2>
                                <button onClick={handlePopupClose} type='button' aria-label='Close Popup' className='focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 hover:bg-gray-100 p-2 rounded-full'>
                                    <svg className='h-6 w-6 text-gray-500' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div className='grid grid-cols-1 gap-4'>
                                <p className='text-gray-600 mb-4'>{selectedProject.description}</p>
                                {/* Display technologies */}
                                <div className='flex flex-wrap items-center'>
                                    <h3 className='text-lg font-medium text-gray-700 mr-4'>Technologies used:</h3>
                                    <ul className='flex flex-wrap list-none items-end'>
                                        {selectedProject.technologies.map((tech, index) => (
                                            <li key={index} className='text-gray-600 mr-4'>
                                                <span className='inline-block bg-blue-500 rounded-full px-2 py-1 text-xs font-semibold text-white'>{tech}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className='grid grid-cols-1 gap-4'>
                                    <p className='text-gray-600 mb-4'>{selectedProject.popupDescription}</p>
                                    {selectedProject.fonctionTitleList.map((fonctionTitle, index) => (
                                        <div key={index} className='border-b border-gray-200 pb-4 mb-4'>
                                            <h3 className='text-lg font-medium text-gray-700'>{fonctionTitle}</h3>
                                            <ul className='list-inside text-gray-600'>
                                                {selectedProject.fonctionDescriptionList[index] && (
                                                    <li key={index}>{selectedProject.fonctionDescriptionList[index]}</li>
                                                )}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className='flex flex-wrap justify-center w-screen gap-x-10 gap-y-10 my-8 cursor-pointer'>
                {projects.map((e, i) => {
                    return (
                        <div onClick={() => handlePopupOpen(e)} className='bg-white p-4 rounded-xl w-[300px] md:w-1/4 h-fit ' key={i}>
                            <div className={`w-full bg-gray-300 h-60 bg-center bg-contain bg-no-repeat rounded-xl relative`} style={{ backgroundImage: `url(${e.projectImage})` }}>
                                <div className='absolute bottom-0 title z-40 rounded-t-xl self-center bg-white w-32 h-6'>
                                    <p className='text-center text-gray-500 font-semibold'>{e.category}</p>
                                </div>
                            </div>
                            <p className='text-center py-3 text-xl font-semibold'>{e.projectName}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
