import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import logoM from '../Assets/SOLA BEAT SVG.svg';
import logoMW from '../Assets/sola beat svg blanc.svg';
import menul from '../Assets/burger-menu-svgrepo-com.svg'
import menuC from '../Assets/close-lg-svgrepo-com.svg'

export const NavBar = () => {
    const links = [
        { name: "ACCUEIL", link: "/" },
        { name: "QUI SOMMES NOUS ?", link: "/about" },
        { name: "NOS SOLUTIONS", link: "/skills" },
        { name: "CONTACT", link: "/contact" },
    ];

    const [menu, setMenu] = useState(false);
    const [isTransparent, setIsTransparent] = useState(true); // New state for transparency

    const handleMenuToggle = () => {
        setMenu(!menu);
    };

    const handleLinkClick = () => {
        setMenu(false);
    };

    // New useEffect hook for scroll detection
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) { // Adjust threshold as needed (e.g., 100px)
                setIsTransparent(false);
            } else {
                setIsTransparent(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function to remove event listener on component unmount
        return () => window.removeEventListener('scroll', handleScroll);
    }, []); // Empty dependency array to run effect only once

    return (
        <nav
            className={`flex fixed justify-between h-20 w-screen px-6 py-0 items-center transition-all duration-500 ease-in ${isTransparent ? 'bg-transparent' : 'bg-slate-100 shadow-sm shadow-gray-300'
                } z-50`}
        >
            <h2 className='text-slate-500 cursor-pointer select-none self-center'>

                <Link
                    to="/"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={handleLinkClick}
                >
                    <img src={isTransparent ? logoMW : logoM} alt="" className='w-40 -top-8 left-0 right-0 absolute' />
                </Link>{' '}
            </h2>

            <div
                onClick={handleMenuToggle}
                className='text-3xl absolute top-6 right-8 cursor-pointer md:hidden'
            >
                <img src={menul} alt=""  width="30" height="30px"/>
                {/* <ion-icon name={'menu'}></ion-icon> */}
            </div>

            <ul
                className={`md:flex md:pt-1 md:items-end md:pb-3 absolute md:static md:z-auto md:self-center z-20 
                right-0 w-screen duration-200 h-screen md:w-auto md:pl-0 px-2  md:flex-row md:h-full flex flex-col items-start
                pt-24 md:justify-evenly md:bg-transparent bg-white ${menu ? 'top-0 opacity-100' : 'top-[-2250px]'
                    } md:opacity-100 opacity-0 `}
            >
                {links.map((l) => (
                    <React.Fragment key={l.name}>
                        <div
                            onClick={handleMenuToggle}
                            className='text-3xl  absolute right-8 top-8 cursor-pointer md:hidden'
                        >
                             <img src={menuC} alt=""  width="30" height="30px"/>
                            {/* <ion-icon name={'close'}></ion-icon> */}
                        </div>
                        <li
                            className={`list text-sm md:mx-4 font-semibold text-slate-500 select-none transition-all duration-500 ease-in ${menu ? 'py-7 px-10 mx-auto md:py-0' : 'md:py-0 py-0'
                                } ${isTransparent && menu == false ? 'text-white' : ''}`} // Text color change logic
                        >
                            <Link
                                to={l.link}
                                spy={true}
                                smooth={true}
                                duration={500}
                                onClick={handleLinkClick}
                            >
                                {l.name}{' '}
                            </Link>{' '}
                        </li>
                    </React.Fragment>
                ))}
            </ul>
        </nav>
    );
};
