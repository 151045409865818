import React, { useRef, useEffect, useState } from 'react';
import './About.css'; // Import CSS file for custom animations

export const About = () => {
  const aboutRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { top, bottom } = aboutRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      // Check if the top or bottom of the about section is in the viewport
      if (top < windowHeight && bottom > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      id='about'
      ref={aboutRef}
      className={`w-screen h-full py-16 flex flex-col justify-between items-center sm:px-16 ${isVisible ? 'animated' : ''
        }`}
    >
      <h1 className={`text-3xl sm:text-5xl font-semibold mb-8 ${isVisible ? 'animated-title' : ''}`}>QUI SOMMES <span className='text-[#db6f15]'> NOUS ?</span></h1>
      <div className='flex flex-col items-center lg:items-center lg:flex-row '>
        <div className={`flex flex-col mb-2 justify-center w-full px-8 sm:px-14 ${isVisible ? 'animated-left-to-right' : ''}`}>
          <h3 className='text-lg sm:text-2xl font-semibold mb-4 '>Sola Beat</h3>
          <p className='text-gray-500 text-sm sm:text-lg'>
            SolaBeat est une entreprise spécialisée dans le domaine de l’informatique et du digital qui accompagne les entreprises dans leur croissance en leur offrant des solutions sur mesure.
            Nous nous concentrons sur les domaines clés tels que la téléphonie IP (VoIP), la sécurité informatique et l’infrastructure informatique. Grâce à nos solutions de téléphonie IP, les entreprises peuvent améliorer leurs communications internes et externes en utilisant les dernières technologies et les meilleures marques du marché.
            Nous proposons également des solutions de sécurité informatique pour protéger les données des entreprises contre les menaces potentielles. En termes d’infrastructure informatique, nous proposons des solutions pour améliorer les performances et la disponibilité des systèmes d’entreprise. Nous offrons également des intégrations de CRM et d’ERP pour améliorer les processus commerciaux des entreprises. De plus, nous sommes spécialisés dans la vente de matériel de visioconférence pour améliorer les communications en entreprise.
          </p>
        </div>
        <div className={` ${isVisible ? 'animated-right-to-left bg-transparent' : ''}`}>
          <iframe src="https://lottie.host/embed/1d0366e7-1b30-4272-8b28-7e7f43423246/4T3zgvzryo.json" width={"300px"} height={"300px"}></iframe>
        </div>
      </div>
      <div></div>
    </div>
  );
};
