import React, { useState, useEffect, useRef } from 'react';

export const OurNumbers = () => {
  const [projectsCount, setProjectsCount] = useState(0);
  const [clientsCount, setClientsCount] = useState(0);
  const [solutionsCount, setSolutionsCount] = useState(0);
  const [reussiteCount, setReussiteCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const numbersRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (numbersRef.current) {
      observer.observe(numbersRef.current);
    }
    return () => {
      if (numbersRef.current) {
        observer.unobserve(numbersRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      // Function to increment counts at regular intervals
      const incrementCounts = () => {
        const projectsInterval = setInterval(() => {
          setProjectsCount((prevCount) => {
            const newCount = prevCount + 1;
            return newCount >= 150 ? 150 : newCount; // Stop at 50 projects
          });
        }, 1); // Interval for projects count increment

        const clientsInterval = setInterval(() => {
          setClientsCount((prevCount) => {
            const newCount = prevCount + 1;
            return newCount >= 100 ? 100 : newCount; // Stop at 100 clients
          });
        }, 1); // Interval for clients count increment

        const solutionsInterval = setInterval(() => {
          setSolutionsCount((prevCount) => {
            const newCount = prevCount + 1;
            return newCount >= 30 ? 30 : newCount; // Stop at 30 solutions
          });
        }, 1); // Interval for solutions count increment
        const reussiteInterval = setInterval(() => {
          setReussiteCount((prevCount) => {
            const newCount = prevCount + 1;
            return newCount >= 100 ? 100 : newCount; // Stop at 30 solutions
          });
        }, 1); // Interval for solutions count increment

        // Cleanup intervals on component unmount or when reaching desired counts
        return () => {
          clearInterval(projectsInterval);
          clearInterval(clientsInterval);
          clearInterval(solutionsInterval);
          clearInterval(reussiteInterval);
        };
      };

      incrementCounts(); // Start incrementing counts
    }
  }, [isVisible]);

  return (
    <div ref={numbersRef} className='w-screen h-50 bg-slate-700 text-white flex  flex-col gap-y-5 md:gap-y-0 md:flex-row justify-center px-10 items-center py-20'>
      <div className='flex justify-evenly flex-wrap text-2xl  w-screen  items-center'>
        <div className='cursor-pointer rounded-full w-full md:w-auto p-3 flex flex-col justify-center items-center'>
          <span className={`count ${isVisible ? 'bigNumber' : ''} text-6xl `} >{projectsCount + "+"}</span><span className='text-lg font-semibold mt-4 text-slate-300'>PROJETS RÉALISÉS</span>
        </div>
        <div className='cursor-pointer rounded-full p-3 w-full md:w-auto flex flex-col justify-center items-center'>
          <span className={`count ${isVisible ? 'bigNumber' : ''} text-6xl`}>{clientsCount}</span> <span className='text-xl font-semibold mt-2 text-slate-300'><span className='text-lg font-semibold mt-4 text-slate-300'>CLIENTS</span></span>
        </div>
        <div className='cursor-pointer rounded-full p-3 w-full md:w-auto flex flex-col justify-center items-center'>
          <span className={`count ${isVisible ? 'bigNumber' : ''} text-6xl`}>{solutionsCount + "+"}</span> <span className='text-xl font-semibold mt-2 text-slate-300'><span className='text-lg font-semibold mt-4 text-slate-300'>SOLUTIONS SUPPORTÉES</span></span>
        </div>
        <div className='cursor-pointer rounded-full p-3 w-full md:w-auto flex flex-col justify-center items-center'>
          <span className={`count ${isVisible ? 'bigNumber' : ''} text-6xl`}>{reussiteCount + "%"}</span> <span className='text-xl font-semibold mt-2 text-slate-300'><span className='text-lg font-semibold mt-4 text-slate-300'>TAUX DE RÉUSSITE</span></span>
        </div>
      </div>
    </div>
  );
};
